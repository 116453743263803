var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { full: "" } },
    [
      _c("div", { staticClass: "header" }, [
        _c("img", {
          attrs: {
            src: require("../../../assets/img/home/nav_icon_close.svg"),
            alt: "",
          },
          on: { click: _vm.close },
        }),
      ]),
      _vm.list && _vm.list.length
        ? _c("div", { staticClass: "onpic" }, [
            _c("img", { attrs: { src: _vm.list[0].resUrl, alt: "" } }),
          ])
        : _vm._e(),
      _vm.list && _vm.list.length
        ? _c(
            "van-tabbar",
            {
              attrs: { route: "", "active-color": "#7d7e80" },
              on: { change: _vm.onChange },
              model: {
                value: _vm.active,
                callback: function ($$v) {
                  _vm.active = $$v
                },
                expression: "active",
              },
            },
            [
              _c(
                "van-tabbar-item",
                {
                  attrs: { replace: "", icon: "home-o" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function (props) {
                          return [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/img/album/icon_nft.png"),
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1051688
                  ),
                },
                [_vm._v(" " + _vm._s(_vm.$t("lang.mintNFT")) + " ")]
              ),
              _c(
                "van-tabbar-item",
                {
                  attrs: { replace: "", icon: "search" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function (props) {
                          return [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/img/album/icon_add.png"),
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    55170613
                  ),
                },
                [_vm._v(" " + _vm._s(_vm.$t("lang.addTo")) + " ")]
              ),
              _vm.list[0].encrypt !== "1"
                ? _c(
                    "van-tabbar-item",
                    {
                      attrs: { replace: "", icon: "friends-o" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function (props) {
                              return [
                                _c("img", {
                                  attrs: {
                                    src: require("../../../assets/img/album/icon_send.png"),
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1886006888
                      ),
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lang.share")) + " ")]
                  )
                : _vm._e(),
              _c(
                "van-tabbar-item",
                {
                  attrs: { replace: "", icon: "friends-o" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function (props) {
                          return [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/img/album/icon_more.png"),
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1037654209
                  ),
                },
                [_vm._v(" " + _vm._s(_vm.$t("lang.more")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-popup",
        {
          staticClass: "addPic_popup",
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("lang.addTo"))),
          ]),
          _vm._l(_vm.albumList, function (item) {
            return _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "padding-left": "20px",
                  height: "68px",
                },
                on: {
                  click: function ($event) {
                    return _vm.addToAlbum(item.metanetId)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "48px",
                      height: "48px",
                      "border-radius": "6px",
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: {
                        src:
                          item.folderIcon ||
                          require("../../../assets/img/home/albumico.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      color: "#bfc2cc",
                      "margin-left": "10px",
                      "padding-left": "20px",
                    },
                  },
                  [
                    _c("div", { staticStyle: { color: "#303133" } }, [
                      _vm._v(_vm._s(item.folderName)),
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(item.fileTotal) + _vm._s(_vm.$t("lang.items"))
                      ),
                    ]),
                  ]
                ),
              ]
            )
          }),
        ],
        2
      ),
      _c(
        "van-popup",
        {
          staticClass: "more_popup",
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.showMore,
            callback: function ($$v) {
              _vm.showMore = $$v
            },
            expression: "showMore",
          },
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("lang.more"))),
          ]),
          _c("div", { staticClass: "content" }, [
            _c("div", { on: { click: _vm.viewTx } }, [
              _vm._v(_vm._s(_vm.$t("lang.checkTX"))),
            ]),
            _c("div", { on: { click: _vm.viewPic } }, [
              _vm._v(_vm._s(_vm.$t("lang.details"))),
            ]),
          ]),
        ]
      ),
      _c(
        "van-popup",
        {
          staticClass: "more_popup",
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.showPicDetail,
            callback: function ($$v) {
              _vm.showPicDetail = $$v
            },
            expression: "showPicDetail",
          },
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("lang.details"))),
          ]),
          _c("div", { staticClass: "content" }, [
            _c("div", [
              _vm._v(
                _vm._s(_vm.$t("lang.format")) +
                  _vm._s(_vm.list[0] && _vm.list[0].dataType)
              ),
            ]),
            _c("div", [
              _vm._v(
                _vm._s(_vm.$t("lang.uploadTime")) +
                  _vm._s(_vm.list[0] && _vm.list[0].dateTime)
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }