<template>
  <layout full>
    <div class="header">
      <img src="../../../assets/img/home/nav_icon_close.svg" alt="" @click="close">
    </div>
    <div v-if="list && list.length" class="onpic">
      <img :src="list[0].resUrl" alt="">
    </div>
<!--    <div class="bottom">-->
<!--      <div>-->
<!--        <div></div>-->
<!--        <div>一键铸造NFT</div>-->
<!--      </div>-->
<!--      <div>-->
<!--        <div></div>-->
<!--        <div>添加到</div>-->
<!--      </div>-->
<!--      <div>-->
<!--        <div></div>-->
<!--        <div>发布时光</div>-->
<!--      </div>-->
<!--      <div>-->
<!--        <div></div>-->
<!--        <div>更多</div>-->
<!--      </div>-->
<!--    </div>-->
    <van-tabbar v-model="active" route @change="onChange" active-color="#7d7e80" v-if="list && list.length">
      <van-tabbar-item replace icon="home-o">
        {{ $t('lang.mintNFT') }}
        <template #icon="props">
          <img :src="require('../../../assets/img/album/icon_nft.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace icon="search">
        {{ $t('lang.addTo') }}
        <template #icon="props">
          <img :src="require('../../../assets/img/album/icon_add.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace icon="friends-o" v-if="list[0].encrypt!=='1'">
        {{ $t('lang.share') }}
        <template #icon="props">
          <img :src="require('../../../assets/img/album/icon_send.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace icon="friends-o">
        {{ $t('lang.more') }}
        <template #icon="props">
          <img :src="require('../../../assets/img/album/icon_more.png')" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <van-popup v-model="show" round position="bottom" class="addPic_popup">
      <div class="title">{{ $t('lang.addTo') }}</div>
      <div v-for="item in albumList" style="display: flex; align-items: center; padding-left: 20px; height: 68px" @click="addToAlbum(item.metanetId)">
        <div style="width: 48px; height: 48px; border-radius: 6px">
          <img :src="item.folderIcon || require('../../../assets/img/home/albumico.png')" alt="" style="width: 100%; height: 100%">
        </div>
        <div style="text-align: center; color: #bfc2cc; margin-left: 10px; padding-left: 20px">
          <div style="color: #303133;">{{item.folderName}}</div>
          <div>{{item.fileTotal}}{{ $t('lang.items') }}</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showMore" round position="bottom" class="more_popup">
      <div class="title">{{ $t('lang.more') }}</div>
      <div class="content">
        <div @click="viewTx">{{ $t('lang.checkTX') }}</div>
        <div @click="viewPic">{{ $t('lang.details') }}</div>
      </div>
    </van-popup>
    <van-popup v-model="showPicDetail" round position="bottom" class="more_popup">
      <div class="title">{{ $t('lang.details') }}</div>
      <div class="content">
        <div>{{ $t('lang.format') }}{{ list[0] && list[0].dataType }}</div>
        <div>{{ $t('lang.uploadTime') }}{{ list[0] && list[0].dateTime }}</div>
      </div>
    </van-popup>
  </layout>
</template>

<script>
import { getImageMetaFile, getMyMetaPhotoFolder } from 'api/api'
import { Popup } from 'vant'
import common from '@/utils/common'
import {hexToBase64} from "sdk";
export default {
  name: "PhotoView",
  data() {
    return {
      list: [],
      active: 0,
      show: false,
      showMore: false,
      showPicDetail: false,
      encrypt: 0,
      albumList: []
    }
  },
  components: {
    [Popup.name]: Popup
  },
  mounted() {
    localStorage.removeItem('chooseup')

    let p = {
      txIds: [this.$route.query.address]
    }
    getImageMetaFile(p).then(res => {
      this.list = res.data.results.items
      this.list.forEach(item => {
        this.getEncryptData(item.txId).then((res) => {
          if (res) {
            this.$store.state.sdk.eciesDecryptData(res).then((response) => {
              item.resUrl = hexToBase64(response.data.data)
              item.dateTime = new Date(item.timestamp).format('yyyy-MM-dd hh:mm:ss')
              item.dataType = item.dataType
            })
          }
        })
      })
    })
  },
  methods: {
    getEncryptData (txId) {
      return new Promise(async(resolve, reject) => {
        let p = {
          txIds: [txId]
        }
        await getImageMetaFile(p).then(res => {
          resolve(res.data.results.items[0].data)
        })
      })
    },
    close() {
      this.$router.back()
    },
    onChange(index) {
      this.active = -1 //
      if (this.list[0].encrypt !== '1') {
        if (index === 0) {
          this.$toast(this.$t('lang.comingSoon'))
          return
        } else if (index === 1) {
          this.show = true
          let params = {
            metaId: localStorage.getItem('showId'),
            page: 1,
            pageSize: 15,
            timestamp: 0,
            folderMetanetId: ''
          }
          getMyMetaPhotoFolder(params).then(res => {
            this.albumList = res.data.results.items
          })
        } else if (index === 2) {
          localStorage.setItem('chooseup', JSON.stringify(
              {'type': 'object', 'data': [`metafile://${this.$route.query.address}`]}
          ))

          this.$router.push({
            path: '/photo_send2'
          })
        } else if (index === 3) {
          this.showMore = true
        }
      } else {
        if (index === 0) {
          this.$toast(this.$t('lang.comingSoon'))
          return
        } else if (index === 1) {
          this.show = true
          let params = {
            metaId: localStorage.getItem('showId'),
            page: 1,
            pageSize: 15,
            timestamp: 0,
            folderMetanetId: ''
          }
          getMyMetaPhotoFolder(params).then(res => {
            this.albumList = res.data.results.items
          })
        } else if (index === 2) {
          this.showMore = true
        }
      }

    },
    viewTx() {
      let txId = this.$route.query.address
      location.href = `https://whatsonchain.com/tx/${txId}`
    },
    viewPic() {
      this.showMore = false
      this.showPicDetail = true
    },
    addToAlbum(metanetId) {
      let params = {
        "folderID": metanetId, // 所属的文件夹metanetID
        "option": '', // 0添加 1删除
        "txs": [this.$route.query.address],
        "createTime": +new Date()
      }

      const txData = common.getTxData({
        nodeName: 'metaphotoItem',
        brfcId: 'c31caffd28ab',
        path: '/Protocols/metaphotoItem',
        data: JSON.stringify(params),
        payTo: [
          {
            amount: 500,
            address: process.env.VUE_APP_PlatformAddress
          }
        ]
      })

      this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
        this.$toast(this.$t('lang.success'))
        this.show = false
      }, (err) => {

      })
    }
  }
}
</script>

<style lang="less" scoped>
  .header {
    height: 48px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    img {
      cursor: pointer;
    }
  }
  .onpic {
    height: 80vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .more_popup {
    .title {
      margin: 30px 0 25px;
      color: #313131;
      font-size: 20px;
      text-align: center;
    }
    .content {
      margin-top: 20px;
      div {
        height: 60px;
        line-height: 60px;
        background: #fff;
        padding-left: 20px;
        font-size: 14px;
      }
    }
  }
  .addPic_popup {
    .title {
      margin: 30px 0 25px;
      color: #313131;
      font-size: 20px;
      text-align: center;
    }
  }
  //.bottom {
  //  position: fixed;
  //  bottom: 0;
  //  left: 0;
  //  display: flex;
  //  flex: 1;
  //  width: 100%;
  //  height: 50px;
  //  > div {
  //    flex: 1;
  //    display: flex;
  //    justify-content: center;
  //    align-items: center;
  //  }
  //}
</style>